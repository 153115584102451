import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../styles/careers/interviewmid.module.scss";
import { InterviewLink3 } from "./InterviewLink3";
import { InterviewLink4 } from "./InterviewLink4";
import { InterviewLink5 } from "./InterviewLink5";
import { InterviewLink2 } from "./InterviewLink2";
import quotationStart from "../../images/career_interview/midQuotationStart.png";
import quotationEnd from "../../images/career_interview/midQuotationEnd.png";
import firstImg from "../../images/career_interview/gyui/_kpo1299.jpg";
import { InterviewLink1 } from "./InterviewLink1";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/career_new_top/_kpo0802_newgraduate_head.jpg";
import gyuiLastImg from "../../images/career_interview/gyui/lastImage.png";

export const Interview1Content = ({ prefix }, { location: pathname }) => (
  <>
    <SEO
      pathname={pathname}
      title="インタビュー１ | suprieve-now"
      description="スプリーブホールディングスで働く社員にインタビュー | 「アジア全体で使えるサービスを開発していきたい」（ギュイ / システム事業部 / 2016年入社）"
      image={seoImage}
    />

    <div className={styles.all}>
      <div className={styles.nameContainerRight}>
        <StaticImage
          className={styles.nameImg}
          src="../../images/career_interview/gyui/_kpo1283.jpg"
          alt=""
        />
      </div>
      <div className={styles.employee}>
        <h2>Employee Interveiw</h2>
      </div>
      <div className={styles.nameContainerLeft}>
        <div className={styles.floatName}>
          <h2>チューン ギン アイン ギュイ</h2>
          <h3>システム事業部</h3>
          <h4>2016年入社</h4>
          <h5>
            アジア全体で使える
            <br />
            サービスを開発していきたい
          </h5>
        </div>
      </div>

      <div className={styles.summary}>
        <h2>Career Summary</h2>
        <p>
          2006/04～2008/06 （2年）: ユニコテクノス株式会社
          <br />
          日本で携帯電話端末ソフトウェアをBREWアプリとして開発
        </p>
        <p>
          2008/07～2016/04 （8年）: We Are Engineering 株式会社
          <br />
          ベトナムオフショアで携帯電話端末ソフトウェアをAndroid・IPhoneアプリとして開発
        </p>
        <p>
          2016/05～現在（4年）：Suprieve 株式会社
          <br />
          在庫管理/自動受注/人材管理システムの開発
        </p>
      </div>

      <div className={styles.workContainer}>
        <StaticImage
          className={styles.workImg}
          src="../../images/career_interview/gyui/_kpo1288.jpg"
          alt=""
        />
        <div className={styles.workContainerRight}>
          <div className={styles.triangle}> </div>
          <div className={styles.workTitle}>
            <h2>仕事内容</h2>
          </div>
          <p>
            イルミルド製薬の在庫管理/自動受注システムの開発保守を主担当
            <br />
            Evand株式会社向けの人材管理システムに開発保守を主担当
            <br />
            業種の違う部署であり人材事業部については聞きなれない日本語が多くあり戸惑いも多くあります。
          </p>
        </div>
      </div>

      {/* SP-ONLY */}
      <div className={styles.firstImageSp}>
        <img className={styles.firstImageBodySp} src={firstImg} alt="" />
      </div>

      <div className={styles.backgroundSquare} />

      <div className={styles.interviewContent}>
        <div className={styles.firstContainer}>
          <div className={styles.firstContainerLeft}>
            <h3>ギュイさんが所属するシステム事業部のご紹介をお願いします。</h3>
            <p>
              私は日本語検定2級を持っていますがスムーズに日本語で仕事ができているかというと同僚には迷惑をかけている部分もあると思っています。
              <br />
              ですが、皆さんとても親切に接してくれているので、日本に来てSuprieveに来てよかったと思っています。
              日本では海外の人はマイノリティーではありますが、それを感じない環境はシステム事業部としても会社としても整っていると思います。
              開発においては全員が前職からの同僚だったこともあり、スムーズな開発ができていると感じています。
            </p>
            <h3>ギュイさんからみたSuprieveはどのような会社ですか？</h3>
            <p>
              前段と重なるのですが、雰囲気はとてもよく働きやすい環境です。
              初めて打合せする他部署の方とも意見交換などで困ったり戸惑うようなことはこれまで経験していないぐらいです。
              <br />
              気がつくといろんな部署が増えていっているので、各部署で適切なシステムを提供できるように何をどうやっているのが勉強しています。
            </p>
          </div>

          <div className={styles.firstContainerRight}>
            <img className={styles.firstImg} src={firstImg} alt="" />
          </div>
        </div>

        <div className={styles.secondContainer}>
          <div className={styles.secondContainerLeft}>
            <div>
              <h2>
                <img src={quotationStart} />
                いろんな方に感謝される
                <img src={quotationEnd} />
              </h2>
            </div>

            <h3>仕事をする上での「働きがい」や「やりがい」は感じますか？</h3>
            <p>
              自動受注にしても人材管理の勤怠管理にしても導入をすれば便利になった手間がなくなったといろんな方に感謝されるのでそれまでの苦労はやっぱり報われますね。僕が関わっているシステムは一部しか外部販売にたどり着けていないので、多くの会社で多くの方が触れてもらえるシステムをリリースしたいと思っています。
              <br />
              将来的にはベトナムなどアジア全体で使えるサービスを開発していきたいです。
            </p>
          </div>
          <div className={styles.secondContainerRight}>
            <p>&nbsp;</p>
          </div>
        </div>

        <div className={styles.thirdContainer}>
          <h3>今後の目標を教えてください</h3>
        </div>

        <div className={styles.fourthContainer}>
          <div className={styles.fourthContainerRight}>
            <h2>
              <img src={quotationStart} />
              技術を通じて社会に貢献する
              <img src={quotationEnd} />
            </h2>
          </div>
        </div>

        <div className={styles.fifthContainer}>
          <div className={styles.fifthContainerLeft}>
            <img className={styles.fifthImg} src={gyuiLastImg} alt="" />
          </div>
          <div className={styles.fifthContainerRight}></div>
        </div>
      </div>

      <div className={styles.otherInterview}>
        <h2>他の先輩のインタビューを見る</h2>

        <div className={styles.interviewContainer}>
          <InterviewLink1 prefix={prefix} />
          <InterviewLink2 prefix={prefix} />
          <InterviewLink3 prefix={prefix} />
          <InterviewLink4 prefix={prefix} />
          <InterviewLink5 prefix={prefix} />
        </div>

        <div className={styles.interviewContainerSp}>
          <InterviewLink1 prefix={prefix} />
          <InterviewLink2 prefix={prefix} />
          <InterviewLink3 prefix={prefix} />
          <InterviewLink4 prefix={prefix} />
          <InterviewLink5 prefix={prefix} />
        </div>
      </div>
    </div>
  </>
);
