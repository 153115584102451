import React, {useContext} from "react";
import {CareerMidNoLayout} from "../../../../organisms/careers/CareerMidLayout";
import {Interview1Content} from "../../../../organisms/interviews/Interview1Content";
import {SuprieveNowHeader} from "../../../../organisms/menus/SuprieveNowHeader";
import {Layout} from "../../../../organisms/Layout";
import {WindowSizeContext} from "../../../../atoms/core/WindowSizeProvidor";
import {md} from "../../../../atoms/core/media-queries";

const Content = () => {
  const {width: windowWidth} = useContext(WindowSizeContext);
  const extraHeaders = windowWidth > md ? <SuprieveNowHeader current="/1" prefix="/careers/mid/suprieve-now"/> : null;
  return <CareerMidNoLayout current="suprieve-now"
                            extraHeaders={extraHeaders}>
    <Interview1Content prefix="/careers/mid/suprieve-now"/>
  </CareerMidNoLayout>;
};
export default () => <Layout><Content/></Layout>;